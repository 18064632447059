import React, { useState, useCallback } from 'react';
import { Typography, Divider, Fab, Button, Alert } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';

import DocumentTypesField from './DocumentTypesField';
import DOCUMENT_TYPES from '../../documentTypes';
import { searchDocs } from '../../../actions/search';
import SearchField from './SearchField';
import SearchResults from './SearchResults';
// import SearchNumDocsField from './SearchNumDocsField';
import { useConfiguration } from '../../../providers/ConfigurationContext';

function SearchDocuments({ selectDocument }) {
  const configuration = useConfiguration();
  const [search, setSearch] = useState('');
  const [num_docs] = useState(20);
  const [num_docs_error] = useState(false);
  const [search_error, setSearchError] = useState(false);
  const [search_results, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reference_search, setReferenceSearch] = useState('');
  const [reference_search_error, setReferenceSearchError] = useState(false);
  const [reference_search_results, setReferenceSearchResults] = useState([]);
  const [reference_loading, setReferenceLoading] = useState(false);
  const [documents_filter, setDocumentsFilter] = useState(DOCUMENT_TYPES.SOP);
  const [selected_document, setSelectedDocument] = useState(null);
  const [selected_reference_document, setSelectedReferenceDocument] =
    useState(null);

  const handleSearchSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const docs = await searchDocs(
        configuration.api,
        search,
        parseInt(num_docs),
        documents_filter.search_filter
      );

      const unique_docs = [];
      const doc_ids = new Set();
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        if (!doc_ids.has(doc.doc_id)) {
          unique_docs.push(doc);
          doc_ids.add(doc.doc_id);
        }
      }
      setSearchResults(unique_docs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [
    search,
    num_docs,
    documents_filter.search_filter,
    setSearchResults,
    configuration.api,
  ]);

  const handleReferenceSearchSubmit = useCallback(async () => {
    setReferenceLoading(true);
    try {
      const docs = await searchDocs(
        configuration.api,
        reference_search,
        parseInt(num_docs),
        documents_filter.value === DOCUMENT_TYPES.SOP.value
          ? DOCUMENT_TYPES.GUIDANCE.search_filter
          : DOCUMENT_TYPES.SOP.search_filter
      );

      const unique_docs = [];
      const doc_ids = new Set();
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        if (!doc_ids.has(doc.doc_id)) {
          unique_docs.push(doc);
          doc_ids.add(doc.doc_id);
        }
      }
      setReferenceSearchResults(unique_docs);
      setReferenceLoading(false);
    } catch (error) {
      console.log(error);
      setReferenceLoading(false);
    }
  }, [
    reference_search,
    num_docs,
    documents_filter.value,
    setReferenceSearchResults,
    configuration.api,
  ]);

  const handleDocSelection = useCallback(
    async (id) => {
      const chunk = search_results.find((chunk) => chunk.id === id);

      setSelectedDocument(chunk);
    },
    [search_results, setSelectedDocument]
  );

  const handleReferenceDocSelection = useCallback(
    async (id) => {
      const chunk = reference_search_results.find((chunk) => chunk.id === id);

      setSelectedReferenceDocument(chunk);
    },
    [reference_search_results, setSelectedReferenceDocument]
  );

  const saveDocId = useCallback(async () => {
    selectDocument(
      selected_document.doc_id,
      selected_document.title,
      selected_reference_document.doc_id
    );
  }, [selected_document, selected_reference_document, selectDocument]);

  return (
    <Grid container spacing={2}>
      <Grid lg={12} md={12} xs={12}>
        <Typography variant='h5'>Document Selection</Typography>
      </Grid>
      <Grid lg={4} md={6} xs={12} align='left'>
        <DocumentTypesField
          value={documents_filter}
          setValue={setDocumentsFilter}
        />
      </Grid>
      {/* <Grid lg={4} md={6} xs={12} align='left'>
        <SearchNumDocsField
          value={num_docs}
          setValue={setNumDocs}
          error={num_docs_error}
          setError={setNumDocsError}
        />
      </Grid> */}
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        paddingBottom={'15px'}
        spacing={1}
      >
        <Grid xs={11} lg={11} md={11}>
          <SearchField
            value={search}
            setValue={setSearch}
            error={search_error || num_docs_error}
            setError={setSearchError}
            onSubmit={handleSearchSubmit}
          />
        </Grid>
        <Grid xs={1} lg={1} md={1} align='right'>
          <Fab
            color='primary'
            aria-label='send'
            onClick={handleSearchSubmit}
            size='small'
            disabled={!documents_filter || loading || num_docs_error}
          >
            <SearchIcon />
          </Fab>
        </Grid>
      </Grid>
      {search_results.length ? (
        <>
          <Grid lg={12} md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <Typography variant='h6'>Results</Typography>
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <SearchResults
              docs={search_results}
              setSelectedDocument={handleDocSelection}
            />
          </Grid>
          {selected_document ? (
            <>
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Typography variant='h6'>
                  <b>{selected_document?.title}</b> selected for audit
                </Typography>
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Typography variant='h5'>
                  Reference Document Selection
                </Typography>
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  xs={12}
                  paddingBottom={'15px'}
                  spacing={1}
                >
                  <Grid xs={11} lg={11} md={11}>
                    <SearchField
                      value={reference_search}
                      setValue={setReferenceSearch}
                      error={reference_search_error || num_docs_error}
                      setError={setReferenceSearchError}
                      onSubmit={handleReferenceSearchSubmit}
                    />
                  </Grid>
                  <Grid xs={1} lg={1} md={1} align='right'>
                    <Fab
                      color='primary'
                      aria-label='send'
                      onClick={handleReferenceSearchSubmit}
                      size='small'
                      disabled={reference_loading || num_docs_error}
                    >
                      <SearchIcon />
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
              {reference_search_results.length ? (
                <>
                  <Grid lg={12} md={12} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid lg={12} md={12} xs={12}>
                    <Typography variant='h6'>Results</Typography>
                  </Grid>
                  <Grid lg={12} md={12} xs={12}>
                    <SearchResults
                      docs={reference_search_results}
                      setSelectedDocument={handleReferenceDocSelection}
                    />
                  </Grid>
                  {selected_reference_document ? (
                    <>
                      <Grid lg={12} md={12} xs={12}>
                        <Divider />
                      </Grid>
                      <Grid lg={12} md={12} xs={12}>
                        <Typography variant='h6'>
                          <b>{selected_reference_document?.title}</b> selected
                          as reference
                        </Typography>
                      </Grid>
                      <Grid lg={12} md={12} xs={12}>
                        <Button onClick={saveDocId} variant='contained'>
                          Start Chat
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid lg={12} md={12} xs={12}>
                      <Alert severity='info'>
                        Select a reference document to compare
                      </Alert>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid lg={12} md={12} xs={12}>
                  <Alert severity='info'>
                    Select a reference document to compare
                  </Alert>
                </Grid>
              )}
            </>
          ) : (
            <Grid lg={12} md={12} xs={12}>
              <Alert severity='info'>Select a document to audit</Alert>
            </Grid>
          )}
        </>
      ) : (
        <Grid lg={12} md={12} xs={12}>
          <Alert severity='info'>Search for a document to audit</Alert>
        </Grid>
      )}
    </Grid>
  );
}

export default SearchDocuments;
