const DOCUMENT_TYPES = {
  GUIDANCE: {
    label: 'FDA Guidance',
    value: 'guidance',
    key: 'guidance',
    search_filter: ['guidance'],
  },
  STANDARDS: {
    label: 'Standards',
    value: 'standards',
    key: 'standards',
    search_filter: ['standards'],
  },
  SOP: {
    label: 'Procedures (SOPs)',
    value: 'sop',
    key: 'sop',
    search_filter: ['sop'],
  },
  DHF: {
    label: 'Design History (DHFs)',
    value: 'dhf',
    key: 'dhf',
    search_filter: ['dhf'],
  },
};
export default DOCUMENT_TYPES;
