import React, { useState } from 'react';
import {
  Accordion,
  Typography,
  AccordionSummary,
  Stack,
  AccordionDetails,
  AccordionActions,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useConfiguration,
  useConfigurationDispatch,
} from '../../../providers/ConfigurationContext';
import { SET_GENERATION_CONFIG } from '../../../providers/actionTypes';
import MaxNewTokensField from './MaxNewTokensField';
import TemperatureField from './TemperatureField';
import TopPField from './TopPField';
import NumDocsField from './NumDocsField';
import TypicalPField from './TypicalPField';

function GenerationParameters() {
  const configuration = useConfiguration();
  const dispatch = useConfigurationDispatch();
  const [revision, setRevision] = useState(0);

  const [max_new_tokens, setMaxNewTokens] = useState(
    configuration?.generation?.max_new_tokens
  );
  const [temperature, setTemperature] = useState(
    configuration?.generation?.temperature
  );
  const [top_p, setTopP] = useState(configuration?.generation?.top_p);
  const [num_docs, setNumDocs] = useState(configuration?.generation?.num_docs);
  const [typical_p, setTypicalP] = useState(
    configuration?.generation?.typical_p
  );
  const [expanded, setExpanded] = useState(false);

  const setSettings = () => {
    dispatch({
      type: SET_GENERATION_CONFIG,
      payload: {
        max_new_tokens,
        temperature,
        top_p,
        num_docs,
        typical_p,
      },
    });
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  const resetSettings = () => {
    setMaxNewTokens(configuration?.generation?.max_new_tokens);
    setTemperature(configuration?.generation?.setTemperature);
    setTopP(configuration?.generation?.top_p);
    setNumDocs(configuration?.generation?.num_docs);
    setTypicalP(configuration?.generation?.typical_p);
    setExpanded(false);
    setRevision((prevState) => prevState + 1);
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Stack direction='row' spacing={2} alignItems={'center'}>
          <Typography variant='h6' paragraph gutterBottom>
            Generation Parameters
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} xs={12} md={12} lg={12}>
          <Grid lg={12} md={12} xs={12}>
            <MaxNewTokensField
              key={`max_new_tokens_${revision}`}
              value={max_new_tokens}
              setValue={setMaxNewTokens}
            />
          </Grid>
          <Grid lg={6} md={12} xs={12}>
            <TemperatureField
              key={`temperature_${revision}`}
              value={temperature}
              setValue={setTemperature}
            />
          </Grid>
          <Grid lg={6} md={12} xs={12}>
            <TopPField
              key={`top_p_${revision}`}
              value={top_p}
              setValue={setTopP}
            />
          </Grid>
          <Grid lg={6} md={12} xs={12}>
            <NumDocsField
              key={`num_docs_${revision}`}
              value={num_docs}
              setValue={setNumDocs}
            />
          </Grid>
          <Grid lg={6} md={12} xs={12}>
            <TypicalPField
              key={`typical_p_${revision}`}
              value={typical_p}
              setValue={setTypicalP}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button variant='outlined' color='inherit' onClick={resetSettings}>
          Reset
        </Button>
        <Button variant='outlined' color='primary' onClick={setSettings}>
          Update
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

export default GenerationParameters;
