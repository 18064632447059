const SOPS = [
  {
    id: '200001',
    name: 'Software Development Planning',
  },
  {
    id: '200002',
    name: 'Software Requirements Specifications',
  },
  {
    id: '200003',
    name: 'Software Verification',
  },
  {
    id: '200004',
    name: 'Software Configuration Management',
  },
  {
    id: '200005',
    name: 'Software Design',
  },
  {
    id: '200006',
    name: 'Software Release Test Plan',
  },
];
export default SOPS;
