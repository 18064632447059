import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import SOPS from './SOPs';

function SOPField({ value, setValue }) {
  const [selected, setSelected] = useState(value);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        justifyContent: 'center',
        justifyItems: 'center',
        textAlign: 'justify',
      }}
    >
      <Autocomplete
        id='dhf-type-field'
        options={SOPS}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        disableClearable={true}
        renderInput={(params) => (
          <TextField {...params} required label='Select the DHF Type' />
        )}
      />
    </Box>
  );
}

export default SOPField;
