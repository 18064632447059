import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SAMPLE_WORK_INSTRUCTION from './sampleWorkInstruction';

function WorkInstructionViewDialog({ open, handleClose }) {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth='md'>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid xs>
            <b>200002_AccQsure_00_SOP_Software Requirement Specifications</b>
          </Grid>
          <Grid xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid xs={12} md={12} lg={12}>
              <Markdown remarkPlugins={[remarkGfm]}>
                {SAMPLE_WORK_INSTRUCTION}
              </Markdown>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default WorkInstructionViewDialog;
