import React from 'react';

import { Box, FormControlLabel, Switch } from '@mui/material';

function IncludeHistoryField({ disabled, value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label='Include Chat History'
        disabled={disabled}
      />
    </Box>
  );
}

export default IncludeHistoryField;
