import { createContext, useContext, useReducer } from 'react';
import { SET_PAGE_TITLE } from './actionTypes';

const PageTitleContext = createContext(null);

const PageTitleDispatchContext = createContext(null);

const initialState = '';

export function PageTitleProvider({ children }) {
  const [pageTitle, dispatch] = useReducer(pageTitleReducer, initialState);

  return (
    <PageTitleContext.Provider value={pageTitle}>
      <PageTitleDispatchContext.Provider value={dispatch}>
        {children}
      </PageTitleDispatchContext.Provider>
    </PageTitleContext.Provider>
  );
}

export function usePageTitle() {
  return useContext(PageTitleContext);
}

export function usePageTitleDispatch() {
  return useContext(PageTitleDispatchContext);
}

function pageTitleReducer(pageTitle, action) {
  switch (action.type) {
    case SET_PAGE_TITLE: {
      return action.payload;
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}
