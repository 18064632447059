import React from 'react';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadButton = ({ name, onChange, disabled, ...rest }) => {
  let input;
  return (
    <>
      <Button
        {...rest}
        onClick={(e) => {
          e.preventDefault();
          input.click();
        }}
      >
        <UploadFileIcon />
      </Button>

      <input
        ref={(element) => (input = element)}
        hidden
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
        type='file'
      />
    </>
  );
};
export default UploadButton;
