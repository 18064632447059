import React from 'react';

import { Container, Box, Typography } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import BotCard from './BotCard';

function Landing() {
  const bots = [
    {
      id: 'voyager',
      title: 'Voyager',
      text: 'Search standards, SOPs, and DHFs with a real-time natural language chat interface.',
      link: '/voyager',
      image: '/img/voyager.svg',
    },
    {
      id: 'cartographer',
      title: 'Cartographer',
      text: 'Build SOPs and other template out of the latest standards and guidance.',
      link: '/cartographer',
      image: '/img/cartographer.svg',
      comingSoon: true,
    },
    {
      id: 'quartermaster',
      title: 'Quartermaster',
      text: 'Audit your quality documentation and interrogate specific documents.',
      link: '/quartermaster',
      image: '/img/quartermaster.svg',
    },
    {
      id: 'qplus',
      title: 'Quartermaster+',
      text: 'Validate project documents against standards and best practices.',
      link: '/qplus',
      image: '/img/quartermaster_plus.svg',
    },
    // {
    //   id: 'scribe',
    //   title: 'Scribe',
    //   text: 'Generate change control documentation and design specifications directly from your code',
    //   link: '/scribe',
    //   image: '/img/scribe.svg',
    //   comingSoon: true,
    // },
  ];

  return (
    <Box sx={{ display: 'flex', paddingTop: '10px' }}>
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant='h4' gutterBottom align='center'>
              Welcome to the AccQsure&#8482; Quality Knowledge Engine.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant='body1' gutterBottom align='center'>
              Select an experience to get started.
            </Typography>
          </Grid>
          {bots.map((bot) => {
            return (
              <Grid key={bot.id} xs={12} md={6} lg={6} align='center'>
                <BotCard key={bot.id} {...bot} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}

export default Landing;
