import axios from 'axios';

import { createContext, useContext, useReducer } from 'react';
import {
  SET_GENERATION_CONFIG,
  SET_MODEL_CONFIG,
  SET_API,
} from './actionTypes';

const ConfigurationContext = createContext(null);

const ConfigurationDispatchContext = createContext(null);

function buildApi(endpoint) {
  const api = axios.create({
    baseURL: `${endpoint}/v1`,
  });

  api.interceptors.request.use((config) => {
    if (config.url !== '/config/auth') {
      // add token to request headers

      const token = localStorage.getItem('accqsure_jwt');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  });
  return api;
}

const initialState = {
  generation: {
    max_new_tokens:
      parseInt(localStorage.getItem('generation_max_new_tokens')) || 1024,
    temperature:
      parseFloat(localStorage.getItem('generation_temperature')) || 0.8,
    top_p: parseFloat(localStorage.getItem('generation_top_p')) || 0.95,
    num_docs: parseInt(localStorage.getItem('generation_num_docs')) || 5,
    typical_p: parseFloat(localStorage.getItem('generation_typical_p')) || 0.95,
  },
  model: null,
  api: null,
};

export function ConfigurationProvider({ children }) {
  const [configuration, dispatch] = useReducer(
    configurationReducer,
    initialState
  );

  return (
    <ConfigurationContext.Provider value={configuration}>
      <ConfigurationDispatchContext.Provider value={dispatch}>
        {children}
      </ConfigurationDispatchContext.Provider>
    </ConfigurationContext.Provider>
  );
}

export function useConfiguration() {
  return useContext(ConfigurationContext);
}

export function useConfigurationDispatch() {
  return useContext(ConfigurationDispatchContext);
}

function configurationReducer(configuration, action) {
  switch (action.type) {
    case SET_MODEL_CONFIG: {
      return {
        ...configuration,
        model: { ...action.payload },
      };
    }
    case SET_API: {
      return {
        ...configuration,
        api: buildApi(action.payload || process.env.REACT_APP_API_URL),
      };
    }
    case SET_GENERATION_CONFIG: {
      Object.entries(action.payload).forEach(([k, v]) => {
        if (v !== undefined) {
          localStorage.setItem(`generation_${k}`, v);
        } else {
          localStorage.removeItem(`generation_${k}`);
        }
      });
      return {
        ...configuration,
        generation: { ...action.payload },
      };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
