import React, { useCallback, useMemo, useState } from 'react';
import { Typography, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import round from '../../../util/round';
import SourceDocumentDialog from './SourceDocumentDialog';

function SearchResults({ docs, setSelectedDocument }) {
  const [doc_open, setDocOpen] = useState(false);
  const [active_view_doc, setActiveViewDoc] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
      setSelectedDocument(value[0]);
    },
    [setRowSelectionModel, setSelectedDocument]
  );

  const handleModalClose = useCallback(() => {
    setDocOpen(false);
    setActiveViewDoc(null);
  }, [setDocOpen, setActiveViewDoc]);

  const renderPage = useCallback((params) => {
    const doc = params.row;
    const doc_page =
      doc.start_page && doc.start_page !== doc.end_page
        ? `${doc.start_page} - ${doc.end_page}`
        : doc.start_page
        ? `${doc.start_page}`
        : doc.page_number
        ? `${doc.page_number}`
        : undefined;
    return <Typography variant='body'>{doc_page}</Typography>;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'certainty',
        headerName: 'Match %',
        type: 'number',
        width: 100,
        renderCell: (params) => {
          if (params.row?.certainty) {
            return `${round(params.row.certainty * 100, 4)}%`;
          }
        },
      },
      {
        field: 'title',
        headerName: 'Document Title',
        type: 'string',
        flex: 1,

        // renderCell: renderName,
      },
      {
        field: 'page_number',
        headerName: 'Page Number',
        type: 'number',
        flex: 0.25,
        renderCell: renderPage,
        // renderCell: renderName,
      },
      {
        field: 'open',
        headerName: 'Open',
        renderCell: (params) => {
          return (
            <Link
              component='button'
              variant='body2'
              onClick={() => {
                setActiveViewDoc(params.row);
                setDocOpen(true);
              }}
            >
              <Typography>Open</Typography>
            </Link>
          );
        },
        flex: 0.25,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex: 0.25,
      },
    ],
    [renderPage]
  );

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            flexGrow: 1,
            '& .deal-history-theme--header': {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
          }}
        >
          <DataGrid
            rowHeight={25}
            autoHeight
            getRowId={(row) => row.id}
            columns={columns}
            rows={docs || []}
            onSelectionModelChange={handleRowSelection}
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={rowSelectionModel}
          />
        </div>
      </div>
      {doc_open ? (
        <SourceDocumentDialog
          doc={active_view_doc}
          open={doc_open}
          handleClose={handleModalClose}
        />
      ) : undefined}
    </div>
  );
}
export default SearchResults;
