const SOP_CHECKS = {
  200001: [
    {
      id: 100,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200001_SOP_Software Development Planning',
      prompt:
        'Does the document have the following sections: Purpose, Scope, Roles and Responsibilities, References, Definitions and Acronyms, Work Instruction, Software Development Planning, Records?',
    },
    {
      id: 101,
      type: 'sop',
      name: 'Has Clear Purpose',
      reference: '200001_SOP_Software Development Planning',
      prompt: 'Is the purpose of this SOP is clearly stated and concise?',
    },
    {
      id: 102,
      type: 'sop',
      name: 'Purpose IEC 62304 Alignment',
      reference: '200001_SOP_Software Development Planning',
      prompt: 'Is the purpose is aligned with the IEC 62304 standards?',
    },
    {
      id: 103,
      type: 'sop',
      name: 'Has Clear Scope',
      reference: '200001_SOP_Software Development Planning',
      prompt:
        'Is the scope of this SOP is clearly defined and applicable to all software development activities related to medical devices?',
    },
    {
      id: 104,
      type: 'sop',
      name: 'Scope IEC 62304 Alignment',
      reference: '200001_SOP_Software Development Planning',
      prompt: 'Is the scope is aligned with the IEC 62304 standards?',
    },
    {
      id: 105,
      type: 'sop',
      name: 'Scope IEC 62304 Alignment',
      reference: '200001_SOP_Software Development Planning',
      prompt: 'Is the scope is aligned with the IEC 62304 standards?',
    },
  ],
  200002: [
    {
      id: 200,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Does the document have the following sections: Purpose, Scope, Roles and Responsibilities, Work Instruction, Approval, Revision, Records?',
    },
    {
      id: 201,
      type: 'sop',
      section: 'global',
      name: 'Has Required Work Instruction Sub Sections',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Does the Work Instruction section have following sub sections: Overview, Elicitation, Analysis, Traceability?',
    },

    {
      id: 202,
      type: 'sop',
      section: 'Purpose',
      name: 'Has Clear Purpose',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the purpose of the Software Requirements Specification (SRS) clearly stated?',
    },
    {
      id: 203,
      type: 'sop',
      section: 'Purpose',
      name: 'Has Explained Significance',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the significance of the Software Requirements Specification (SRS) in the software development lifecycle explained?',
    },
    {
      id: 204,
      type: 'sop',
      section: 'Purpose',
      name: 'Has Explained Significance',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are the reasons for creating the Software Requirements Specification (SRS) document outlined?',
    },
    {
      id: 205,
      type: 'sop',
      section: 'Scope',
      name: 'Has Defined Scope',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the scope of the Software Requirements Specification (SRS) document defined?',
    },
    {
      id: 206,
      type: 'sop',
      section: 'Scope',
      name: 'Includes Software Components and Systems',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are the software components or systems covered by the Software Requirements Specification (SRS) specified?',
    },
    {
      id: 207,
      type: 'sop',
      section: 'Scope',
      name: 'Has Clearly Stated Limits/Exclusions',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are any limitations or exclusions of the Software Requirements Specification (SRS) clearly stated?',
    },
    {
      id: 208,
      type: 'sop',
      name: 'Has SRS Specific Terms Defined',
      section: 'Definitions',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are key terms and definitions specific to the Software Requirements Specification (SRS) listed?',
    },
    {
      id: 209,
      type: 'sop',
      name: 'Has Clear and Concise Definitions',
      section: 'Definitions',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: 'Are definitions clear and concise?',
    },
    {
      id: 210,
      type: 'sop',
      name: 'Term Use is Consistent',
      section: 'Definitions',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are the definitions of all key terms specific to the Software Requirements Specification (SRS) consistent throughout the document?',
    },
    {
      id: 211,
      type: 'sop',
      name: 'Roles Outlined',
      section: 'Roles and Responsibilities',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are roles involved in the creation, review, and maintenance of the Software Requirements Specification (SRS) outlined?',
    },
    {
      id: 212,
      type: 'sop',
      name: 'Clearly Stated Responsibilities',
      section: 'Roles and Responsibilities',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are responsibilities for gathering requirements, documenting them, and ensuring they are met throughout the development process clearly stated?',
    },
    {
      id: 213,
      type: 'sop',
      name: 'Roles and Responsibilities are Consistent',
      section: 'Roles and Responsibilities',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are roles and responsibilities consistent with the document's purpose and scope?`,
    },
    {
      id: 214,
      type: 'sop',
      name: 'SOP Purpose Clearly Stated',
      section: 'Work Instruction - Overview',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the purpose of the SOP for creating, managing, and maintaining the Software Requirements Specification (SRS) document clearly stated?',
    },
    {
      id: 215,
      type: 'sop',
      name: 'SOP is Designed for SRS',
      section: 'Work Instruction - Overview',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the SOP designed to guide the creation, management, and maintenance of the Software Requirements Specification (SRS) document?',
    },
    {
      id: 216,
      type: 'sop',
      section: 'Work Instruction - Elicitation',
      name: 'Elicitation Process Documented',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Is the process of gathering input from various sources to create the Software Requirements Specification (SRS) described?',
    },
    {
      id: 217,
      type: 'sop',
      name: 'Elicitation Outcome Documented',
      section: 'Work Instruction - Elicitation',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are inputs from customer interviews, stakeholder meetings, existing documentation, market research, and regulatory requirements specified?',
    },
    {
      id: 218,
      type: 'sop',
      name: 'Elicitation Includes Different Teams',
      section: 'Work Instruction - Elicitation',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are different teams' contributions to the elicitation process outlined?`,
    },
    {
      id: 219,
      type: 'sop',
      section: 'Work Instruction - Analysis',
      name: 'Has Analysis Phase Categorization and Detail',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt:
        'Are the various types of requirements that will form the Software Requirements Specification (SRS) categorized and detailed?',
    },
    {
      id: 220,
      type: 'sop',
      section: 'Work Instruction - Analysis',
      name: 'Requirement Categories Clear and Concise',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are the requirement categories listed in a clear and concise manner?`,
    },
    {
      id: 221,
      type: 'sop',
      section: 'Work Instruction - Analysis',
      name: `Requirement Meet the Specification's Goals`,
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are the requirements affordable, bounded, modifiable, readable, and meet the specification's stated goals?`,
    },
    {
      id: 222,
      type: 'sop',
      section: 'Work Instruction - Traceability',
      name: 'Requirements Traceable to Test Cases',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are requirements specified in the Software Requirements Specification (SRS) traceable downstream to test cases?`,
    },
    {
      id: 223,
      type: 'sop',
      section: 'Work Instruction - Traceability',
      name: 'Requirements Traceable to Product Requirements',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are requirements specified in the Software Requirements Specification (SRS) traceable upstream to product requirements?`,
    },
    {
      id: 224,
      type: 'sop',
      section: 'Work Instruction - Traceability',
      name: 'Requirements Aligned to Project Goals',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Is alignment with overall project goals ensured?`,
    },
    {
      id: 225,
      type: 'sop',
      section: 'Approval',
      name: 'Approval Process Documented',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Is the process for reviewing and approving the Software Requirements Specification (SRS) documented?`,
    },
    {
      id: 226,
      type: 'sop',
      section: 'Approval',
      name: 'Approval and Sign-offs Documented',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are the roles responsible for sign-off specified?`,
    },
    {
      id: 227,
      type: 'sop',
      section: 'Approval',
      name: 'Required Documentation Outlined',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are any required documentation or checklists outlined?`,
    },
    {
      id: 228,
      type: 'sop',
      section: 'Revision',
      name: 'Revision Process Documented',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Is the process for revising the Software Requirements Specification (SRS) documented?`,
    },
    {
      id: 229,
      type: 'sop',
      section: 'Revision',
      name: 'Version Control Specified',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Is version control specified?`,
    },
    {
      id: 230,
      type: 'sop',
      section: 'Revision',
      name: 'Changes Documented and Communicated',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are changes to the Software Requirements Specification (SRS) documented and communicated to relevant stakeholders?`,
    },
    {
      id: 231,
      type: 'sop',
      section: 'Records',
      name: 'Records Storage Process Documented',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are records related to the Software Requirements Specification (SRS), including the document itself and any related approvals, revisions, or traceability matrices, stored and maintained?`,
    },
    {
      id: 232,
      type: 'sop',
      section: 'Records',
      name: 'Records Secure and Accessible',
      reference: '200002_SOP_Software Requirements Specifications',
      prompt: `Are the records stored and maintained in a secure and accessible manner?`,
    },
  ],
  200003: [
    {
      id: 300,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200003_SOP_Software Verification',
      prompt:
        'Does the document have the following sections: Purpose, Scope, Roles and Responsibilities, References, Definitions and Acronyms, Test Planning?',
    },
    {
      id: 301,
      type: 'sop',
      section: 'global',
      name: 'Has Required Test Planning Sub Sections',
      reference: '200003_SOP_Software Verification',
      prompt:
        'Does the Test Planning section have following sub sections: Software Verification Plan, Software Verification Strategy, Software Verification Types, Hardware and Software Environment, Tools and Equipment required for Testing, Criteria for Verification testing, Assumptions and Dependencies, Test Deliverables, Defect Management, Deviation Policy, Software Release Test Plan, Software Release Test Strategy, Acceptance Criteria, Hardware and Software Environment, Tools, Datasets and Equipment Required for Testing, Test Deliverables, Planned Deviation?',
    },
  ],
  200004: [
    {
      id: 400,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200004_SOP_Software Configuration Management',
      prompt:
        'Does the document have the following sections? Purpose, Scope, Roles and Responsibilities, References, Definitions and Acronyms, Work Instruction?',
    },
    {
      id: 401,
      type: 'sop',
      section: 'global',
      name: 'Has Required Work Instruction Sub Sections',
      reference: '200004_SOP_Software Configuration Management',
      prompt:
        'Does the Work Instruction section have following sub sections: Company Organization, Allocation of Resources, Resourcing and Scheduling, Software Configuration Management Work Instruction, Planning, Identification, Software Configuration Items, Tools, Versioning, Baselines, Change Control, Branching and Merging, Environments, Building, Interfaces and Suppliers, Records?',
    },
  ],
  200005: [
    {
      id: 500,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200005_SOP_Software Design',
      prompt:
        'Does the document have the following sections? Purpose, Scope, Roles and Responsibilities, References, Definitions and Acronyms, Work Instruction, Software Detail Design?',
    },
  ],
  200006: [
    {
      id: 600,
      type: 'sop',
      section: 'global',
      name: 'Has Required Sections',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Does the document have the following sections? Purpose, Scope, Roles and Responsibilities, References, Definitions and Acronyms, Software Test Plan?',
    },
    {
      id: 601,
      type: 'sop',
      section: 'global',
      name: 'Has Required Software Test Plan Sub Sections',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Does the Software Test Plan section have following sub sections: Software Test Plan, Document Approvers, Software Release Test Strategy, Acceptance Criteria, Hardware and Software Environment, Tools, Datasets and Equipment Required for Testing, Test Deliverables, Planned Deviation?',
    },
    {
      id: 602,
      type: 'sop',
      name: 'Has Clear Purpose',
      reference: '200006_SOP_Software Release Test Plan',
      prompt: 'Is the purpose of the document clearly stated?',
    },
    {
      id: 603,
      type: 'sop',
      name: 'Has Specific Scope',
      reference: '200006_SOP_Software Release Test Plan',
      prompt: 'Are the scope and boundaries of the document clearly defined?',
    },
    {
      id: 604,
      type: 'sop',
      name: 'Has Clear Software Test Engineer Responsibilities',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the roles and responsibilities of the Software Test Engineer clearly defined?',
    },
    {
      id: 605,
      type: 'sop',
      name: 'References Documented',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the references to other documents or procedures clearly listed?',
    },
    {
      id: 606,
      type: 'sop',
      name: 'Clear and Consistent Definitions',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the definitions and acronyms clearly defined and consistent throughout the document?',
    },
    {
      id: 607,
      type: 'sop',
      name: 'Has Document Approvers',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the document approvers clearly listed and accountable for the document?',
    },
    {
      id: 608,
      type: 'sop',
      name: 'Has Comprehensive Test Strategy',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Is the software release test strategy clearly outlined and comprehensive?',
    },
    {
      id: 609,
      type: 'sop',
      name: 'Has Measurable Acceptance Criteria',
      reference: '200006_SOP_Software Release Test Plan',
      prompt: 'Are the acceptance criteria clearly defined and measurable?',
    },
    {
      id: 610,
      type: 'sop',
      name: 'Has Documented HW/SW Environments',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the hardware and software environments clearly identified and documented?',
    },
    {
      id: 611,
      type: 'sop',
      name: 'Has Tools, Datasets, and Equipment Listed',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are the tools, datasets, and equipment required for testing clearly listed and validated?',
    },
    {
      id: 612,
      type: 'sop',
      name: 'Has Approved Test Deliverables',
      reference: '200006_SOP_Software Release Test Plan',
      prompt: 'Are the test deliverables clearly listed and approved?',
    },
    {
      id: 613,
      type: 'sop',
      name: 'Planned Deviations are Documented',
      reference: '200006_SOP_Software Release Test Plan',
      prompt:
        'Are planned deviations from the SVP and/or SW Verification Work Instruction clearly documented and assessed?',
    },
  ],
};
export default SOP_CHECKS;
