import React from 'react';

import { Typography, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function Suggestions({ suggestions, onClickSuggestion }) {
  return (
    <Grid container spacing={1} style={{ padding: '15px' }}>
      <Grid lg={12} md={12} xs={12}>
        <Typography variant='overline'>Examples</Typography>
      </Grid>
      {suggestions.map((suggestion) => {
        return (
          <Grid lg={6} md={6} xs={12}>
            <Chip
              onClick={() => onClickSuggestion(suggestion)}
              label={suggestion}
              variant='outlined'
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
export default Suggestions;
