export const getAssets = async (api, chat_id) => {
  const resp = await api.get(`/chat/${chat_id}`);
  return resp.data;
};

export const getAsset = async (api, chat_id, asset_id) => {
  const resp = await api.get(`/chat/${chat_id}/asset/${asset_id}`);
  return resp.data;
};

export const insertAsset = async (
  api,
  chat_id,
  file_name,
  content_type,
  data
) => {
  const resp = await api.post(`/chat/${chat_id}/asset`, data, {
    params: { file_name },
    headers: {
      'Content-Type': content_type,
    },
  });
  return resp.data;
};

export const updateAsset = async (
  api,
  chat_id,
  asset_id,
  content_type,
  data
) => {
  const resp = await api.put(`/chat/${chat_id}/asset/${asset_id}`, data, {
    headers: {
      'Content-Type': content_type,
    },
  });
  return resp.data;
};

export const deleteAsset = async (api, chat_id, asset_id) => {
  const resp = await api.delete(`/chat/${chat_id}/asset/${asset_id}`);
  return resp.data;
};
