import React, { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Box,
  IconButton,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  createChat,
  deleteChat,
  getChats,
  updateChat,
} from '../../actions/chat';
import { useUser } from '../../providers/UserContext';
import Chat from './Chat';
import LoadingMask from '../shared/LoadingMask';
import ChatSelection from '../shared/ChatSelection';
import CHAT_TYPES from '../chatTypes';
import ChatEditDialog from '../shared/ChatEditDialog';
import ChatDeleteDialog from '../shared/ChatDeleteDialog';
import SearchDocuments from './SearchDocuments';
import { useConfiguration } from '../../providers/ConfigurationContext';
import { usePageTitleDispatch } from '../../providers/PageTitleContext';
import { SET_PAGE_TITLE } from '../../providers/actionTypes';

const NEW_CHAT_NAME = 'New Audit';
const page_title = 'Quartermaster';

function Quartermaster() {
  const user = useUser();
  const configuration = useConfiguration();
  const [chats, setChats] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [doc_id, setDocId] = useState(null);
  const [reference_doc_id, setReferenceDocId] = useState(null);

  const pageTitleDispatch = usePageTitleDispatch();

  useEffect(() => {
    pageTitleDispatch({ type: SET_PAGE_TITLE, payload: page_title });
  }, [pageTitleDispatch]);

  const handleSetActiveChat = useCallback(
    (chat) => {
      setActiveChat(chat);
      setDocId(chat?.doc_id);
      setReferenceDocId(chat?.reference_doc_id);
    },
    [setActiveChat, setDocId]
  );

  useEffect(() => {
    if (user?.entity_id && !chats) {
      getChats(configuration.api, CHAT_TYPES.QUARTERMASTER)
        .then((data) => {
          data.sort(
            (a, b) =>
              new Date(b.updated_at).valueOf() -
              new Date(a.updated_at).valueOf()
          );
          setChats(data);
          handleSetActiveChat(data[0]);
        })
        .catch((error) => console.log(error));
    }
  }, [user?.entity_id, chats, handleSetActiveChat, configuration.api]);

  useEffect(() => {
    if (user?.entity_id && chats && chats.length === 0) {
      createChat(configuration.api, {
        name: NEW_CHAT_NAME,
        type: CHAT_TYPES.QUARTERMASTER,
      })
        .then((chat) => {
          setChats([chat]);
          handleSetActiveChat(chat);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user?.entity_id, chats, handleSetActiveChat, configuration.api]);

  const handleUpdateChat = async (chat_id, update) => {
    const updated_chat = await updateChat(configuration.api, chat_id, update);
    setChats((prevChats) => {
      const index = prevChats.findIndex((chat) => chat.entity_id === chat_id);
      const new_chats = [...prevChats];
      new_chats[index] = updated_chat;
      return new_chats;
    });
    handleSetActiveChat(updated_chat);
  };

  const handleDeleteChat = async (chat_id) => {
    await deleteChat(configuration.api, chat_id);
    const new_chats = [...chats].filter((chat) => chat.entity_id !== chat_id);
    if (new_chats.length) {
      setChats(new_chats);
      handleSetActiveChat(new_chats[0]);
    } else {
      const new_chat = await createChat(configuration.api, {
        name: NEW_CHAT_NAME,
        type: CHAT_TYPES.QUARTERMASTER,
      });
      setChats([new_chat]);
      handleSetActiveChat(new_chat);
    }
  };

  const handleSetDocId = async (doc_id, doc_name, reference_doc_id) => {
    const update = { doc_id, reference_doc_id };
    if (activeChat.name === NEW_CHAT_NAME) {
      update.name = `${doc_name} Audit`;
    }

    await handleUpdateChat(activeChat.entity_id, update);
  };

  if (!chats) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={2}>
      <Grid lg={2} md={2} xs={2}>
        <Box sx={{ padding: '15px' }}>
          <ChatSelection
            type={CHAT_TYPES.QUARTERMASTER}
            new_name={NEW_CHAT_NAME}
            chats={chats}
            setActiveChat={handleSetActiveChat}
            setChats={setChats}
          />
        </Box>
      </Grid>
      <Grid
        lg={10}
        md={10}
        xs={10}
        container
        spacing={2}
        style={{ padding: '15px' }}
      >
        <Grid lg={12} md={12} xs={12}>
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <Typography variant='h5'>{activeChat?.name}</Typography>
            <IconButton onClick={() => setEditOpen(true)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>

        {doc_id ? (
          <>
            <Grid lg={12} md={12} xs={12}>
              <Alert severity='success'>
                <AlertTitle>
                  Comparing document <b>{doc_id}</b> with reference{' '}
                  <b>{reference_doc_id}</b>
                </AlertTitle>
                Ask questions below
              </Alert>
            </Grid>
            <Chat
              key={activeChat?.updated_at}
              chat={activeChat}
              doc_id={doc_id}
              reference_doc_id={reference_doc_id}
            />
          </>
        ) : (
          <>
            <Grid lg={12} md={12} xs={12}>
              <SearchDocuments
                chat_id={activeChat?.entity_id}
                selectDocument={handleSetDocId}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ChatEditDialog
        key={`edit_${activeChat?.updated_at}`}
        chat={activeChat}
        open={editOpen}
        updateChat={handleUpdateChat}
        handleClose={() => setEditOpen(false)}
      />
      <ChatDeleteDialog
        key={`delete_${activeChat?.updated_at}`}
        chat={activeChat}
        open={deleteOpen}
        deleteChat={handleDeleteChat}
        handleClose={() => setDeleteOpen(false)}
      />
    </Grid>
  );
}

export default Quartermaster;
