import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Initializing from './Initializing';
import Layout from './Layout';
import Landing from './Landing';
import Voyager from './Voyager';
import Quartermaster from './Quartermaster';
import Login from './Auth/Login';
import Callback from './Auth/Callback';
import Logout from './Auth/Logout';
import ProtectedRoute from './ProtectedRoute';
import QPlus from './QPlus';

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Landing />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/voyager',
          element: (
            <ProtectedRoute>
              <Voyager />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/quartermaster',
          element: (
            <ProtectedRoute>
              <Quartermaster />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/qplus',
          element: (
            <ProtectedRoute>
              <QPlus />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/login',
          element: <Login />,
          children: [],
        },
        {
          path: '/logout',
          element: <Logout />,
          children: [],
        },
        {
          path: '/authenticate',
          element: <Callback />,
          children: [],
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_startTransition: true,
    },
  }
);

function App() {
  return <RouterProvider router={router} fallbackElement={<Initializing />} />;
}

export default App;
