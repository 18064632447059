import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useTheme,
  useScrollTrigger,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Badge,
  Button,
  Avatar,
  Typography,
} from '@mui/material';

// import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';

import {
  useConfiguration,
  useConfigurationDispatch,
} from '../../providers/ConfigurationContext';
import {
  SET_AUTH_USER,
  SET_MODEL_CONFIG,
  USER_FETCH,
  USER_LOGOUT,
} from '../../providers/actionTypes';
import { useUser, useUserDispatch } from '../../providers/UserContext';
import { getUser } from '../../actions/user';
import { useLocation } from 'react-router-dom';
import { useAuth, useAuthDispatch } from '../../providers/AuthContext';
import { RefreshSession } from '../RefreshSession';
import { MODEL_CONFIGURATIONS } from '../../models/modelConfigurations';
import { usePageTitle } from '../../providers/PageTitleContext';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 15,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
}

function Header({ colorInvert = false }) {
  const theme = useTheme();
  const location = useLocation();
  const configuration = useConfiguration();
  const configuration_dispatch = useConfigurationDispatch();
  const user = useUser();
  const userDispatch = useUserDispatch();
  const auth = useAuth();
  const auth_dispatch = useAuthDispatch();
  const page_title = usePageTitle();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (auth.config && !configuration?.model) {
      configuration_dispatch({
        type: SET_MODEL_CONFIG,
        payload: {
          llm: MODEL_CONFIGURATIONS[auth.config.llm_model_family],
          vllm: MODEL_CONFIGURATIONS[auth.config.vllm_model_family],
        },
      });
    }
  }, [auth.config, configuration?.model, configuration_dispatch]);

  useEffect(() => {
    if (auth.user_manager && auth.user === undefined) {
      auth.user_manager
        .getUser(configuration.api)
        .then((auth_user) => {
          if (auth_user && auth_user?.expires_at < Date.now() / 1000) {
            auth_dispatch({ type: USER_LOGOUT });
            auth.user_manager
              .removeUser()
              .then(() => {
                auth.user_manager.signinRedirect().then();
              })
              .catch((error) => console.log(error));
          } else {
            auth_dispatch({ type: SET_AUTH_USER, payload: auth_user });
          }
        })
        .catch((error) => {
          console.log(error);
          auth_dispatch({ type: SET_AUTH_USER, payload: null });
        });
    }
  }, [auth_dispatch, auth.user_manager, auth.user, configuration.api]);

  useEffect(() => {
    if (auth.user && !user?.entity_id) {
      getUser(configuration.api)
        .then((result) => {
          userDispatch({ type: USER_FETCH, payload: result });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            // authenticate should handle create user
            if (location.pathname !== '/authenticate') {
              navigate('/authenticate');
            }
          } else {
            auth.user_manager.signoutRedirect();
          }
        });
    }
  }, [
    auth.user,
    auth.user_manager,
    user,
    userDispatch,
    location.pathname,
    navigate,
    configuration.api,
  ]);

  const { mode } = theme.palette;

  const logout = async () => {
    await auth.user_manager.signoutRedirect();
  };

  const menuId = 'primary-navbar-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem component={Link} to='/account'>
        <IconButton size='large' color='inherit'>
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <p>Account</p>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          logout();
          handleMenuClose();
        }}
      >
        <IconButton size='large' color='inherit'>
          <LogoutOutlinedIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-navbar-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size='large' color='inherit'>
          <Badge color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem component={Link} to='https://docs.accqsure.ai'>
        <IconButton size='large' color='inherit'>
          <HelpOutlineOutlinedIcon />
        </IconButton>
        <p>Documentation</p>
      </MenuItem>
      {/* <MenuItem component={Link} to='/account'>
        <IconButton size='large' color='inherit'>
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <p>Account</p>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          logout();
          handleMobileMenuClose();
        }}
      >
        <IconButton size='large' color='inherit'>
          <LogoutOutlinedIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <ElevationScroll>
        <AppBar
          position={'sticky'}
          sx={{
            top: 0,
            display: 'flex',
          }}
        >
          <Toolbar sx={{ backgroundColor: theme.palette.secondary.main }}>
            <Box
              component='a'
              href='/'
              title='AccQSure'
              width={{ xs: 100, md: 175 }}
              sx={{ mr: 2, display: 'flex' }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={1}
              >
                <Box
                  component={'img'}
                  src={
                    mode === 'light' && !colorInvert ? '/logo.png' : '/logo.png'
                  }
                  height={1}
                  width={1}
                />
              </Box>
            </Box>
            <Box sx={{ mr: 2, display: 'flex' }}>
              <Typography
                variant='h5'
                color={theme.palette.common.white}
                sx={{ textTransform: 'capitalize' }}
              >
                {page_title}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1 }} />
            {user.entity_id ? (
              <>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {/* <IconButton size='large' color='primary'>
                    <Badge color='error'>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                  <IconButton
                    size='large'
                    color='primary'
                    href='https://docs.accqsure.ai'
                    target='_blank'
                  >
                    <Badge color='error'>
                      <HelpOutlineOutlinedIcon fontSize='large' />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    // color='primary'
                  >
                    <Avatar
                      // sx={{
                      //   bgcolor: theme.palette.primary.main,
                      // }}
                      alt={user?.first_name}
                      src={user?.picture || '/bogus.jpg'}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size='large'
                    aria-label='show more'
                    aria-controls={mobileMenuId}
                    aria-haspopup='true'
                    onClick={handleMobileMenuOpen}
                    color='primary'
                  >
                    <MoreIcon fontSize='large' />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Button
                onClick={() => auth.user_manager.signinRedirect()}
                variant='contained'
                color='primary'
                size='large'
              >
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {renderMobileMenu}
      {renderMenu}
      {auth.user ? <RefreshSession /> : undefined}
    </>
  );
}

export default Header;
