import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Typography, Box, Link, Divider, Button } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import Grid from '@mui/material/Unstable_Grid2';
import { usePageTitleDispatch } from '../../providers/PageTitleContext';
import { SET_PAGE_TITLE } from '../../providers/actionTypes';
import getFile from '../../util/getFile';
import mammoth from 'mammoth/mammoth.browser';
import TurndownService from '@joplin/turndown';
import * as turndownPluginGfm from '@joplin/turndown-plugin-gfm';
import GDP_CHECKS from './gdpChecks';
import Semaphore from '../../util/Semaphore';
import { generate } from '../../actions/generate';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';

// import { useUser } from '../../providers/UserContext';
import { useConfiguration } from '../../providers/ConfigurationContext';
import DocumentViewDialog from './DocumentViewDialog';
import SOPField from './SOPField';
import SOP_CHECKS from './SOPChecks';
import DocumentControlChecks from './DocumentControlChecks';
import QAChecks from './QAChecks';

const page_title = 'Quartermaster Plus';
const turndownService = new TurndownService();

const gfm = turndownPluginGfm.gfm;
turndownService.use(gfm);

const MAX_CONCURRENT_CHECKS = 10;
const SYSTEM_PROMPT = `You are an expert at documentation review.  Your job is to determine if a document meets the criteria requested.  Answer 'PASSED' if the document conforms to the criteria or 'FAILED' if it does not.  If the test fails, provide a detailed explanation for which the document does not meet the criteria.`;

const PASSED = /^PASSED/;
const FAILED = /^FAILED/;

const calculateStatus = (final_result) => {
  if (!final_result?.generated_text) {
    return 'unknown';
  }
  if (final_result.generated_text.match(PASSED)) {
    return 'passed';
  }
  if (final_result.generated_text.match(FAILED)) {
    return 'failed';
  }
  return 'unknown';
};

function QPlus() {
  // const user = useUser();
  const configuration = useConfiguration();
  const pageTitleDispatch = usePageTitleDispatch();

  const [doc_content, setDocContent] = useState('');
  const [doc_title, setDocTitle] = useState('');
  const [qa_results, setQaResults] = useState(null);
  const [gdp_results, setGdpResults] = useState(null);
  const [docOpen, setDocOpen] = useState(false);
  const [sop, setSOP] = useState(null);

  const gcp_checks = useMemo(() => {
    const checks = [...GDP_CHECKS];
    if (sop) {
      checks.push(
        ...SOP_CHECKS[sop.id].filter((check) => check.section === 'global')
      );
    }
    return checks;
  }, [sop]);

  const document_checks = useMemo(() => {
    if (sop) {
      return [
        ...SOP_CHECKS[sop.id].filter((check) => check.section !== 'global'),
      ];
    }
    return [];
  }, [sop]);

  useEffect(() => {
    pageTitleDispatch({ type: SET_PAGE_TITLE, payload: page_title });
  }, [pageTitleDispatch]);

  const runCheck = useCallback(
    async (doc_check) => {
      const messages = [
        {
          role: 'system',
          content: SYSTEM_PROMPT,
        },
        {
          role: 'user',
          content: `>>DOCUMENT<<\n${doc_content}\n\n>>CRITERIA<<\n${doc_check.prompt}\n`,
        },
      ];
      const response = await generate(
        configuration.api,
        messages,
        2048,
        0.1,
        0.95
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (data === '[DONE]') {
                continue;
              } else {
                const result = JSON.parse(data);
                if (result?.choices) {
                  if (result?.choices[0]?.finish_reason) {
                    finish_reason = result?.choices[0]?.finish_reason;
                  }
                  if (
                    !configuration.model.llm.eos_token.includes(
                      result?.choices[0]?.delta?.content
                    )
                  ) {
                    answer += result?.choices[0]?.delta?.content;
                  }
                } else {
                  if (!result.generated_text) {
                    answer += result.token.text;
                  }
                }
              }
            }
            line = '';
          }
          if (done) {
            break;
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
      const final_status = calculateStatus({
        generated_text: answer,
        finish_reason,
      });
      return { answer, final_status };
    },
    [configuration.api, configuration.model.llm.eos_token, doc_content]
  );

  const handleUpload = useCallback(
    async (files) => {
      if (files[0]) {
        const document = await getFile(files[0]);
        if (document) {
          const html = await mammoth.convertToHtml({
            arrayBuffer: document.contents,
          });
          const markdown = turndownService.turndown(html.value);
          setDocContent(markdown);
          setDocTitle(document.name);
        }
      }
    },
    [setDocContent, setDocTitle]
  );

  const handleRunQAChecks = useCallback(async () => {
    const doc_checks = document_checks
      .filter((check) => check)
      .map((check) => {
        return { ...check, status: 'pending' };
      });

    setQaResults(doc_checks);
    const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);

    await Promise.all(
      doc_checks.map(async (doc_check) => {
        await semaphore.run(async () => {
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.id === doc_check.id
            );
            const new_check = { ...prevState[index], status: 'running' };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
          const result = await runCheck(doc_check);
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.id === doc_check.id
            );
            const new_check = {
              ...prevState[index],
              status: result.final_status,
              result: result.answer,
            };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
        });
      })
    );
  }, [runCheck, setQaResults, document_checks]);

  const handleRunGDPChecks = useCallback(async () => {
    const doc_checks = gcp_checks
      .filter((check) => check)
      .map((check) => {
        return { ...check, status: 'pending' };
      });

    setGdpResults(doc_checks);
    const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);

    await Promise.all(
      doc_checks.map(async (doc_check) => {
        await semaphore.run(async () => {
          setGdpResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.id === doc_check.id
            );
            const new_check = { ...prevState[index], status: 'running' };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
          const result = await runCheck(doc_check);
          setGdpResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.id === doc_check.id
            );
            const new_check = {
              ...prevState[index],
              status: result.final_status,
              result: result.answer,
            };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
        });
      })
    );
  }, [runCheck, setGdpResults, gcp_checks]);

  return (
    <Box paddingX={'30px'} paddingY={'10px'}>
      <Grid container spacing={2}>
        <Grid lg={12} md={12} xs={12}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
            alignItems={'center'}
          >
            <Typography variant='h3'>Welcome to QuarterMaster Plus</Typography>
            <ExploreOutlinedIcon fontSize='large' />
          </Stack>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography variant='h4' textAlign={'center'}>
            Ensuring Document Compliance and Precision
          </Typography>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Divider />
        </Grid>

        {!Boolean(doc_content) ? (
          <Grid lg={12} md={12} xs={12} textAlign={'center'}>
            <Typography variant='h5'>
              Submit Document for Quartermaster Evaluation
            </Typography>
          </Grid>
        ) : undefined}

        <Grid lg={12} md={12} xs={12}>
          <DropzoneArea
            dropzoneText={
              Boolean(doc_content)
                ? 'Upload again to replace document'
                : 'Drag and drop a file here or click'
            }
            onChange={handleUpload}
            showPreviewsInDropzone={false}
          />
        </Grid>
        {Boolean(doc_content) ? (
          <Grid lg={12} md={12} xs={12} textAlign={'center'}>
            <Typography variant='h6' sx={{ wordBreak: 'break-all' }}>
              Uploaded <b>{doc_title}</b>
            </Typography>
            <Link
              component='button'
              variant='h6'
              onClick={() => {
                setDocOpen(true);
              }}
            >
              <Typography variant='h6'>View Contents</Typography>
            </Link>
            <DocumentViewDialog
              title={doc_title}
              contents={doc_content}
              open={docOpen}
              handleClose={() => setDocOpen(false)}
            />
          </Grid>
        ) : undefined}
        <Grid
          lg={12}
          md={12}
          xs={12}
          container
          spacing={2}
          style={{ padding: '15px' }}
        >
          {Boolean(doc_content) ? (
            <>
              {/* <Grid lg={12} md={12} xs={12}>
                <Markdown remarkPlugins={[remarkGfm]}>{doc_content}</Markdown>
              </Grid> */}
              <Grid lg={12} md={12} xs={12}>
                <Box
                  sx={{
                    width: '100%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    justifyItems: 'center',
                  }}
                >
                  <SOPField value={sop} setValue={setSOP} />
                </Box>
              </Grid>
            </>
          ) : undefined}
          {/* {Boolean(sop) && !Boolean(doc_checks) ? (
            <>

              <Grid lg={12} md={12} xs={12}>
                <SOPField value={sop} setValue={setSOP} />
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Typography variant='h5'>Select Checks to Run</Typography>
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <DocumentCheckSelection
                  doc_checks={document_checks}
                  setSelectedChecks={handleRunChecks}
                />
              </Grid>
            </>
          ) : undefined} 
          */}
          {Boolean(sop) ? (
            <>
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12} textAlign={'center'}>
                <Typography variant='h5'>Document Control Insights</Typography>
              </Grid>
              {gdp_results ? (
                <Grid lg={12} md={12} xs={12}>
                  <DocumentControlChecks
                    doc_content={doc_content}
                    doc_checks={gdp_results}
                  />
                </Grid>
              ) : (
                <Grid lg={12} md={12} xs={12}>
                  <Button onClick={handleRunGDPChecks} variant='outlined'>
                    Run Document Control Engine
                  </Button>
                </Grid>
              )}
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12} textAlign={'center'}>
                <Typography variant='h5'>Quality Assurance Insights</Typography>
              </Grid>
              {qa_results ? (
                <Grid lg={12} md={12} xs={12}>
                  <QAChecks doc_content={doc_content} doc_checks={qa_results} />
                </Grid>
              ) : (
                <Grid lg={12} md={12} xs={12}>
                  <Button onClick={handleRunQAChecks} variant='outlined'>
                    Run Quality Assurance Engine
                  </Button>
                </Grid>
              )}
            </>
          ) : undefined}
        </Grid>
      </Grid>
    </Box>
  );
}

export default QPlus;
