export const generate = async (
  api,
  messages,
  max_tokens,
  temperature,
  top_p
) => {
  const token = localStorage.getItem('accqsure_jwt');

  const init = {
    // signal: AbortSignal.timeout(10000),  // this was causing timeouts with long generations
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      messages,
      max_tokens,
      temperature,
      top_p,
    }),
  };

  const response = await fetch(`${api.defaults.baseURL}/generate`, init);

  return response;
};
